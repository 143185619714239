/* Google Font CDN Link */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');
*{
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: "Poppins", sans-serif;
}
body{
	position: relative;
	min-height: 100vh;
	width: 100%;
	/* overflow: hidden; */
}


.text-right{
	text-align: right;
}


.img-neat {
	object-fit: scale-down;
}