.sidebar{
	position: fixed;
	top: 0;
	left: 0;
	height: 100%; 
	width: 78px;
	background: #11101d;
	padding: 6px 14px;
	transition: all 0.5s ease;
}
.sidebar.spreadbar{
	width: 240px;
}
.sidebar .logo_content .logo{
	color: #fff;
	display: flex;
	height: 50px;
	width: 100%;
	align-items: center;
	opacity: 0;
	pointer-events: none;
	transition: all 0.5s ease;
}
.sidebar.spreadbar .logo_content .logo{
	opacity: 1;
	pointer-events: none;
}
.logo_content i{
	font-size: 28px;
	margin-right: 5px;
}
.logo_content .logo .logo_name{
	font-size: 20px;
	font-weight: 400;
}
.sidebar #btn{
	position: absolute;
	color: #fff;
	top: 6px;
	left: 50%;
	font-size: 20px;
	height: 50px;
	width: 50px;
	text-align: center;
	line-height: 50px;
	transform: translateX(-50%);
}
.sidebar.spreadbar #btn{
	left: 90%;
}
.sidebar ul{
	margin-top: 20px; margin-left: -35px;
}
.sidebar ul li{
	position: relative;
	height: 50px;
	width: 100%;
	margin: 0 5px;
	list-style: none;
	line-height: 50px;
}
.sidebar.spreadbar ul li .tooltip{
	display: none;
}
.sidebar ul li .tooltip{
	position: absolute;
	left: 122px;
	top: 0;
	transform: translate(-50%, -50%);
	border-radius: 6px;
	height: 35px;
	width: 122px;
	background: #fff;
	line-height: 35px;
	text-align: center;
	box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
	transition: 0s;
	opacity: 0;
	pointer-events: none;
	display: block;
}
.sidebar ul li:hover .tooltip{
	transition: all 0.5s ease;
	opacity: 1;
	top: 50%;
}
.sidebar ul li input{
	position: absolute;
	height: 100%;
	width: 100%;
	left: 0;
	top: 0;
	border-radius: 12px;
	outline: none;
	border: none;
	background: #1d1b31;
	padding-left: 50px;
	font-size: 18px;
	color: #fff;
}
.sidebar ul li .bx-search{
	position: absolute;
	z-index: 99;
	color: #fff;
	font-size: 22px;
	transition: all 0.5 ease;
}
.sidebar ul li .bx-search:hover{
	background: #fff;
	color: #1d1b31;
}
.sidebar ul li a{
	color: #fff;
	display: flex;
	align-items: center;
	text-decoration: none;
	transition: all 0.4s ease;
	border-radius: 12px;
	white-space: nowrap;
}
.sidebar ul li a.active{
	color: #11101d;
	background: #fff;
}
.sidebar ul li a:hover{
	color: #11101d;
	background: #d0d0d0;
}
.sidebar ul li a.active:hover{
	color: #11101d;
	background: #fff;
}
.sidebar ul li i{
	height: 50px;
	font-size: 25px;
	min-width: 50px;
	border-radius: 12px;
	line-height: 50px;
	text-align: center;
}
.sidebar .links_name{
	font-size: 15px;
	font-weight: 400;
	opacity: 0;
	pointer-events: none;
	transition: all 0.5s ease;
}
.sidebar.spreadbar .links_name{
	transition: 0s;
	opacity: 1;
	pointer-events: auto;
}
.sidebar .profile_content{
	position: absolute;
	color: #fff;
	bottom: 0;
	left: 0;
	width: 100%;
}
.sidebar .profile_content .profile{
	position: relative;
	padding: 10px 6px;
	height: 60px;
	background: none;
	transition: all 0.4s ease;
}
.spreadbar .profile_content .profile{
	background: #1d1b31;
}
.profile_content .profile .profile_details{
	display: flex;
	align-items: center;
	opacity: 0;
	pointer-events: none;
	white-space: nowrap;
}
.sidebar.spreadbar .profile .profile_details{
	opacity: 1;
	pointer-events: auto;
}
.profile .profile_details img{
	height: 45px;
	width: 45px;
	object-fit: cover;
	border-radius: 12px;
}
.profile .profile_details .name_job{
	margin-left: 10px;
}
.profile .profile_details .name{
	font-size: 15px;
	font-weight: 400;
}
.profile .profile_details .job{
	font-size: 12px;
}
.profile #log_out{
	position: absolute;
	bottom: 5px;
	left: 50%;
	transform: translateX(-50%);
	min-width: 50px;
	line-height: 50px;
	font-size: 20px;
	border-radius: 12px;
	text-align: center;
	transition: all 0.4s ease;
	background: #1d1b31;
}
.sidebar.spreadbar .profile #log_out{
	left: 88%;
}
.sidebar.spreadbar .profile #log_out{
	background: none;
}
.home_content{
	position: absolute;
	height: 100%;
	width: calc(100% - 78px);
	left: 78px;
	transition: all 0.5s ease;
}
.home_content .text{
	font-size: 25px;
	font-weight: 500;
	color: #1d1b31;
	margin: 12px;
}
.sidebar.spreadbar ~ .home_content{
	width: calc(100% - 240px);
	left: 240px;
}